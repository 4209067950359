/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import httpServiceAuth from "@/services/http-service";

export let displayIadea = {
  setColor(color) {
    httpServiceAuth
      .post("http://localhost:8080/v2/hardware/light", {
        id: 0,
        name: "frame",
        brightness: 1.0,
        color: color,
      })
      .catch((error) => {
        //
      });
  },
};
