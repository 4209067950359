<template>
  <div class="powered-by-clebex">
    <span class="text" style="color: rgb(194, 194, 194);">Powered by</span>
    <img
      src="../../assets/images/main-nav-footer-logo-white.png"
      alt="Clebex logo"
    />
  </div>
</template>

<script>
export default {
  name: "PoweredByClebex"
};
</script>
