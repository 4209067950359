/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import httpServiceAuth from "@/services/http-service";

export let displayQbic = {
  setRed() {
    httpServiceAuth
      .post("http://localhost:8080/v1/led/side_led", {
        red: 255,
        green: 0,
        blue: 0,
      })
      .catch((error) => {
        //
      });
    httpServiceAuth
      .post("http://localhost:8080/v1/led/front_led", {
        red: 255,
        green: 0,
        blue: 0,
      })
      .catch((error) => {
        //
      });
  },
  setOrange() {
    httpServiceAuth
      .post("http://localhost:8080/v1/led/side_led", {
        red: 255,
        green: 128,
        blue: 0,
      })
      .catch((error) => {
        //
      });
    httpServiceAuth
      .post("http://localhost:8080/v1/led/front_led", {
        red: 255,
        green: 128,
        blue: 0,
      })
      .catch((error) => {
        //
      });
  },
  setGreen() {
    httpServiceAuth
      .post("http://localhost:8080/v1/led/side_led", {
        red: 0,
        green: 204,
        blue: 0,
      })
      .catch((error) => {
        //
      });
    httpServiceAuth
      .post("http://localhost:8080/v1/led/front_led", {
        red: 0,
        green: 204,
        blue: 0,
      })
      .catch((error) => {
        //
      });
  },
};
