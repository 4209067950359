/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

export let displayCrestron = {
  setRed() {
    Crestron.sendBooleanSignal("USB_GREEN_LED_CONTROL", false);
    Crestron.sendBooleanSignal("USB_BLUE_LED_CONTROL", false);
    Crestron.sendBooleanSignal("USB_RED_LED_CONTROL", true);
    Crestron.sendIntegerSignal("USB_RED_LED_BRIGHTNESS", 90);
  },
  setGreen() {
    Crestron.sendBooleanSignal("USB_GREEN_LED_CONTROL", true);
    Crestron.sendBooleanSignal("USB_BLUE_LED_CONTROL", false);
    Crestron.sendBooleanSignal("USB_RED_LED_CONTROL", false);
    Crestron.sendIntegerSignal("USB_GREEN_LED_BRIGHTNESS", 90);
  },
  setOrange() {
    Crestron.sendBooleanSignal("USB_GREEN_LED_CONTROL", true);
    Crestron.sendBooleanSignal("USB_BLUE_LED_CONTROL", false);
    Crestron.sendBooleanSignal("USB_RED_LED_CONTROL", true);
    Crestron.sendIntegerSignal("USB_RED_LED_BRIGHTNESS", 90);
    Crestron.sendIntegerSignal("USB_GREEN_LED_BRIGHTNESS", 90);
  },
};
